import { AppInsightsService } from './../app-insights.service';
import { Injectable, Input, NgZone } from '@angular/core';
import { BaseUrl } from '../shared/constants/url-constants';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap, finalize } from 'rxjs/operators';

const authHeaderString = 'X-Authorization';
const contentTypeHeaderString = 'application/json';

const signInHttpHeaders = new HttpHeaders({
  'Content-Type': contentTypeHeaderString,
  'Accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Headers': authHeaderString,
  'Access-Control-Expose-Headers': authHeaderString,
  'responseType': contentTypeHeaderString,
});

const exposeXAuthHeader = new HttpHeaders({
  'Access-Control-Expose-Headers': authHeaderString,
});


@Injectable()

export class SessionsService {
  appId: string = "KW-APP";
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly appInsightsService: AppInsightsService
  ) { }

  signin(data: any): any {
    const body = JSON.stringify(data);
    return this.http.post<any>(BaseUrl.AUTHENTICATE + '/login', body, { headers: signInHttpHeaders, observe: 'response' })
      .pipe(
      tap(resp => {
        this.resolveSessionParams(resp);
      }));
  }

  signout() {
    this.appInsightsService.clearAuthenticatedUserContext();
    return this.http.get(BaseUrl.AUTHENTICATE + '/logout')
      .pipe(finalize(() => this.onSignoutTap()));
  }

  onSignoutTap() {
    localStorage.clear();
    this.zone.run(() => this.router.navigate(['/sessions/signin']));
  }

  hasResource(auth: string[]) {

    if (localStorage.getItem('token') && auth) {
      const payload = atob(localStorage.getItem('token').split('.')[1]);
      const permissions = JSON.parse(payload).listResource;

      if (auth[1] && permissions.filter(feature => +feature.fid === +auth[0])[0]) {
        return !!permissions.filter(feature => +feature.fid === +auth[0])[0].opId.find(operation => +operation === +auth[1]);
      } else {
        return !!permissions.find(permission => +permission.fid === +auth[0]);
      }
    }
    return false;
  }

  refreshTokenOnEstablishmentChange(establismentId) {

    return this.http.post<any>(`${BaseUrl.AUTHENTICATE}/changeEstablishment/${establismentId}`, null,
      { headers: exposeXAuthHeader, observe: 'response' })
      .pipe(
      tap(resp => {
        this.resolveSessionParams(resp);
      }));
  }

  refreshToken() {

    return this.http.get<any>(`${BaseUrl.AUTHENTICATE}/refreshToken`, { headers: exposeXAuthHeader, observe: 'response' })
      .pipe(
      tap(resp => {
        this.resolveSessionParams(resp);
      }));
  }

  getUserEstablishments() {
    return this.http.get<any>(`${BaseUrl.USER}/userorganizations`);
  }

  resolveSessionParams(resp) {
    const xAuthToken = resp.headers.get(authHeaderString).split(' ');
    const token = xAuthToken[1];
    localStorage.setItem('token', token);
    if (resp.body.heartbeat) {
      localStorage.setItem('session', btoa(JSON.stringify(resp.body)));
    }
  }

  noticeBoardMessage() {
    const href = `${BaseUrl.AUTHENTICATE}/notice-board/` + this.appId;
    return this.http.get<any>(href);
  }

  getIsPes() {
    const href = `${BaseUrl.AUTHENTICATE}/getIsPes`;
    return this.http.get<any>(href);
  }

  ssoLogin(data: any, type: string): any {
    return this.http.post<any>(BaseUrl.AUTHENTICATE + '/v2/login/' + type, data, { headers: signInHttpHeaders, observe: 'response' })
      .pipe(
        tap(resp => {
          this.resolveSessionParams(resp);

        })
      );
  }



}
