import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILogo } from './branding.interface';
import { BaseUrl } from '../../../shared/constants/url-constants';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BrandingService {

  private readonly  logoSrc = new BehaviorSubject('');
  private readonly  logoBackground = new BehaviorSubject('#ffffff');
  currentLogoSrc = this.logoSrc.asObservable();
  currentlogoBackground = this.logoBackground.asObservable();

  constructor(private readonly http: HttpClient) { }

  updateCurrentlogo(logoSrc, logoBackground) {
    this.logoSrc.next(`${logoSrc}`);
    this.logoBackground.next(`${logoBackground}`);
  }

  saveLogo(logoJSON) {
    return this.http.put(`${BaseUrl.ORGANIZATION}/logo`, logoJSON);
  }

  uploadLogo(data) {
    return this.http.post(`${BaseUrl.ORGANIZATION}/logo`, data);
  }

  getLogoByOrgId(organizationUnitId): Observable<ILogo[]> {
    return this.http.get<ILogo[]>(`${BaseUrl.ORGANIZATION}/logo/${organizationUnitId}`);
  }
  deleteLogo(id) {
    return this.http.delete(`${BaseUrl.ORGANIZATION}/logo/${id}`);
  }
}
