import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppConfirmComponent } from './app-confirm.component';

interface IConfirmData {
  title?: string;
  message?: string;
  showTextField?: boolean;
  showTextAreaField?: boolean;
  placeholderTextField?: string;
  placeholderTextAreaField?: string;
  showSelectField?: boolean;
  placeholderSelectField?: string;
  optionsSelectField?: any;
  showSelectRadioButtons?: boolean;
  optionsSelectRadioButton?: string[];
  isRequired?: boolean;
  okButtonName?: any;
  cancelButtonName?: any;
  preSelectedRadioButtonOption?: any;
}

@Injectable()
export class AppConfirmService {

  constructor(private readonly dialog: MatDialog) { }

  public confirm(data: IConfirmData = {}): Observable<boolean> {
    data.title = data.title || '';
    data.message = data.message || '';
    data.isRequired = data.isRequired !== undefined ?
      data.isRequired : true;
    data.placeholderTextField = data.placeholderTextField || 'Add more details:';
    data.placeholderTextAreaField = data.placeholderTextAreaField || 'Reason ';
    data.placeholderSelectField = data.placeholderSelectField || 'Select an option';
    let dialogRef: MatDialogRef<AppConfirmComponent>;
    if (data.cancelButtonName === null || data.cancelButtonName === undefined) {
      data.cancelButtonName = 'Cancel';
    }
    if (data.okButtonName === null || data.okButtonName === undefined) {
      data.okButtonName = 'OK';
    }
    dialogRef = this.dialog.open(AppConfirmComponent, {
      width: '480px',
      disableClose: true,
      data: {
        title: data.title,
        message: data.message,
        showTextField: data.showTextField,
        showTextAreaField: data.showTextAreaField,
        showSelectRadioButtons: data.showSelectRadioButtons,
        placeholderTextField: data.placeholderTextField,
        placeholderTextAreaField: data.placeholderTextAreaField,
        showSelectField: data.showSelectField,
        placeholderSelectField: data.placeholderSelectField,
        optionsSelectRadioButton: data.optionsSelectRadioButton,
        optionsSelectField: data.optionsSelectField,
        isRequired: data.isRequired,
        cancelButtonName: data.cancelButtonName,
        okButtonName: data.okButtonName,
        preSelectedRadioButtonOption: data.preSelectedRadioButtonOption,
      }
    });

    return dialogRef.afterClosed();
  }
}
