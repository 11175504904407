import * as moment from 'moment';
import { FormatConstant } from '../constants/format.constants';
import { AbstractControl } from '@angular/forms';

export class Utility {
    static filterArrayByKeyAndValue(items: any[], key: any, value: any): any[] {
        return items.filter(function (item) {
            return item[key] === value;
        });
    }

    static transformDateToString(date) {
        if (date && moment.isMoment(date)) {
            return date.format();
        } else if (date && !moment.isMoment(date)) {
            return this.transformStringToMomentDate(date).format(FormatConstant.DEFAULT_DATE_FORMAT);
        } else {
            return date;
        }
    }
    static transformStringToDate(date: string) {
        moment.locale('en-gb');
        return moment(date, FormatConstant.DEFAULT_DATE_FORMAT).toDate();
    }

    static transformStringToMomentDate(date: string) {
        moment.locale('en-gb');
        return moment(date, FormatConstant.DEFAULT_DATE_FORMAT);
    }

    static checkDuplicate(enterdValue, control: AbstractControl) {
        const valuesInList = control.value;
        if (valuesInList.length > 0) {
          if (valuesInList.some(value => value.toLowerCase().includes(enterdValue.toLowerCase()))) {
            return true;
          } else {
            return false;
          }
        } else {
           return false;
        }
      }
    static getObjectFromArrayByKeyAndValue(items: any[], key: any, value: any): any {
        const list = items.filter(function (item) {
            return item[key] === value;
        });
        if (list.length === 1) {
            return list[0];
        }
        if (list.length <= 0) {
            return null;
        }
        if (list.length > 1) {
            throw new Error('The specified array has multiple objects with key: ' + key + ' and value: ' + value);
        }
        return null;
    }

    static fixedEncodeURIComponent(str) {
        return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
          return '%' + c.charCodeAt(0).toString(16);
        });
      }

      static isEmpty(value: string): boolean {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return false;
    }

      static replaceString(regex: string, relaceString: string, value: string) {

        if (Utility.isEmpty(value)) {
            return null;
        }
        return value.replace(regex, relaceString);
    }

}
