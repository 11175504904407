import { Injectable, OnInit } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { environment } from '../environments/environment';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router'; 

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  private readonly config: Microsoft.ApplicationInsights.IConfig = { 
    instrumentationKey: environment.appInsights.instrumentationKey 
  }; 
    
  constructor(private readonly router: Router) {
    if (!AppInsights.config) { 
      AppInsights.downloadAndSetup(this.config);
    }
  }

  logPageView(name: string, url?: string, properties?: any, measurements?: any, duration?: number) {
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  logEvent(name: string, properties?: any, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
  }
  
  logError(error: Error, properties?: {[key: string]: string}, measurements?: {[key: string]: number}) {
    AppInsights.trackException(error, null, properties, measurements);
  }

  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string): void {
    AppInsights.setAuthenticatedUserContext(authenticatedUserId, ""+accountId);
  }

  trackException(exception: Error, handledAt?: string, properties?: {[key: string]:string}, measurements?: {[key:string]:number}, severityLevel?: AI.SeverityLevel) {
    AppInsights.trackException(exception, null, properties, measurements, severityLevel);
  }

  clearAuthenticatedUserContext() {
    AppInsights.clearAuthenticatedUserContext();
  }
}