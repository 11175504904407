import { Injectable, EventEmitter } from '@angular/core';
import { Utility } from '../utils/utility';

@Injectable()
export class RbacService {

    isAllowFeature(rabacData: any, roleId: number): boolean {

        const authenticatedUserRoleId = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).roleId;
        const loginUser: any = Utility.filterArrayByKeyAndValue(rabacData, 'id', authenticatedUserRoleId);
        if (this.isSuperUser(authenticatedUserRoleId, rabacData)) {
            return true;
        } else {
            const selectedUser: any = Utility.filterArrayByKeyAndValue(rabacData, 'id', roleId);
            if (loginUser[0].hierarcheyLevel <= selectedUser[0].hierarcheyLevel) {
                return true;
            }
        }
        return false;
    }

    isDeleteFeatureAllow(rabacData: any, roleId: number) {
        const authenticatedUserRoleId = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).roleId;
        if (this.isSuperUser(authenticatedUserRoleId, rabacData)) {
            return true;
        } else {
            const loginUser: any = Utility.filterArrayByKeyAndValue(rabacData, 'id', authenticatedUserRoleId);
            const selectedUser: any = Utility.filterArrayByKeyAndValue(rabacData, 'id', roleId);
            if (loginUser[0].hierarcheyLevel < selectedUser[0].hierarcheyLevel) {
                return true;
            }
        }
        return false;
    }

    isSuperUser(authenticatedUserRoleId, rbacData): boolean {
        const data: any = Utility.filterArrayByKeyAndValue(rbacData, 'id', authenticatedUserRoleId);
        if (data[0].shortName === 'MSU') {
            return true;
        }
        return false;
    }
}