import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { UserActivityComponent } from './user-activity.component';
import { UserActivityService } from './user-activity.service';

@NgModule({
    imports: [ CommonModule, MatButtonModule ],
    declarations: [UserActivityComponent],
    providers: [UserActivityService],
    entryComponents: [UserActivityComponent],
    exports: [ UserActivityComponent ]
  })
  export class UserActivityModule { }
