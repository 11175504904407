import { AppInsightsService } from './app-insights.service';
import { AppService } from './shared/services/app.service';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { BrandingService } from './features/app-settings/branding/branding.service';
import { RoutePartsService } from './shared/services/route-parts.service';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { SessionsService } from './sessions/sessions.service';
import { UserActivityService } from './sessions/user-activity/user-activity.service';
import { UserActivityModule } from './sessions/user-activity/user-activity.module';
import { AuthorizationGuard } from './shared/guards/authorization.guard';
import { HttpRequestInterceptorModule } from './shared/services/request-interceptor.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { CanDeactivateGuard } from './shared/guards/can-deactivate/can-deactivate.guard';
import { SnackBarService } from './shared/services/snack-bar.service';
import { InPageNavService } from './shared/components/in-page-nav/in-page-nav.service';
import { AppDateAdapter, APP_DATE_FORMATS } from './shared/components/date-adapter/date-adapter';
import { AppConfirmModule } from './shared/components/app-confirm/app-confirm.module';
import { RbacService } from './shared/services/RbacService';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {  MsalBroadcastService, MsalGuard, MsalModule, MsalService, MSAL_INSTANCE} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';


export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.kwClientId,
        authority: environment.kwIssuer,
        redirectUri: environment.kwRedirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      }
    });
  }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MatSnackBarModule,
        MatInputModule,
        MatFormFieldModule,
        NoopAnimationsModule,
        HttpRequestInterceptorModule,
        MatNativeDateModule,
        NgHttpLoaderModule.forRoot(),
        MatDialogModule,
        UserActivityModule,
        AppConfirmModule,
        MsalModule,
       
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        BrandingService,
        RoutePartsService,
        AuthenticationGuard,
        AuthorizationGuard,
        CanDeactivateGuard,
        SessionsService,
        UserActivityService,
        SnackBarService,
        AppInsightsService,
        AppService,
        RbacService,
        InPageNavService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
          },
    ],
    bootstrap: [AppComponent],
    
})
export class AppModule {
    constructor(overlayContainer: OverlayContainer) {
        overlayContainer.getContainerElement().classList.add('seq-theme');
      }
}
